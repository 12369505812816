import { defineStore } from "pinia";

export const useAuthStore = defineStore('auth', () => {
  const hostAPI = useRuntimeConfig().public.hostAPI;
  // Token de acesso no cookie acessível via JavaScript, mas ainda com proteção
  const token = ref(useCookie('token', { maxAge: 86400, secure: true }));

  // Refresh token como cookie HttpOnly (não acessível via JavaScript)
  const refreshToken = ref(useCookie('refreshToken', {
    maxAge: 604800,
    secure: true,
    httpOnly: true
  }));

  const redirectUrl = ref(useCookie('redirectUrl', { maxAge: 360 }));

  function setToken(tokenValue: any) {
    token.value = tokenValue;
  }

  function clear() {
    const tokenCookie = useCookie('token');
    tokenCookie.value = '';
    token.value = '';
  }

  function setRedirectUrl(url: string) {
    redirectUrl.value = url;
  }

  async function checkToken(): Promise<Number> {
    try {
      if (!token.value) {
        return 401;
      }

      const data: any = await $fetch(hostAPI + "user/auth/validate", {
        method: "post",
        headers: {
          Authorization: 'Bearer ' + token.value
        },
      });

      return data.status;
    } catch (err) {
      return 401;
    }
  }

  async function checkTokenAdminUser(): Promise<Number> {
    try {
      if (!token.value) {
        return 401;
      }

      const data: any = await $fetch(hostAPI + "user/auth/validate/admin", {
        method: "post",
        headers: {
          Authorization: 'Bearer ' + token.value
        },
      });

      return data.status;
    } catch (err) {
      return 401;
    }
  }

  // Função para atualizar o token
  async function refreshTokenAuth(): Promise<Boolean> {
    try {
      const response = await $fetch('/api/admin/users/auth', {
        method: "post"
      });

      if (response.status === 200) {
        const token = response.data[0].token;
        setToken(token)
        return true;
      }
      else {
        setToken(null)
        return false
      }
    } catch (error) {
      console.error('Failed to refresh token:', error);
      return false;
    }
  };

  return { setToken, checkToken, checkTokenAdminUser, clear, token, setRedirectUrl, redirectUrl, refreshTokenAuth };
});
